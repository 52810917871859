/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 2px;
}

/* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 2px;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("./assets/backgroung.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
}

.common_linerGradient {
  background: linear-gradient(to left, #C2C2C2, #000000);
}

.common_linerGradient:hover {
  background: linear-gradient(to right, #C2C2C2, #000000);
}

.header_lgo {
  height: 100px;
  text-align: center;
  padding: 14px 0px;
}

.header_lgo img {
  height: 100%;
}

.header {
  background-color: #F5F5F5;
  height: 100px;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
}

.logout {
  background-color: #000000;
  color: white;
  height: 37px;
  padding: 0px 22px;
}

.unactive {
  color: rgb(63, 63, 63);
  font-size: 1rem;
  cursor: pointer;
}

.active {
  color: #000;
  font-weight: 600;
  font-size: 1.1rem;
}

.outline_btn {
  border: 1px solid #000;
  color: #000;
}

.task_box {
  background-color: #F1F1F1;
  padding: 10px 18px;
  border-radius: 10px;
  cursor: pointer;
  /* width: 30%; */
}

.predefine_taskBox {
  background-color: #404040;
  padding: 15px 25px;
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
}

.task_add_form .ant-upload.ant-upload-select {
  width: 175px !important;
  height: 147px !important;
  border: 2px dashed #9f9797 !important;
}

.task_add_form .ant-tooltip-placement-top {
  display: none !important;
}

.profile_editIcon {
  position: absolute;
  top: 0;
  left: 18%;
}

.profile_upload {
  cursor: pointer;
}

.attechment_div {
  align-items: center;
  background-color: #ebe8e8;
  display: flex;
  height: 110px;
  justify-content: center;
  width: 152px;
  border-radius: 6px;
}

.attechment_div img {
  height: 100px !important;
}

.notification_div {
  max-height: 450px;
  overflow: auto;
  min-height: 50px;
  min-width: 200px;
}

@media only screen and (max-width: 767px) {

  aside.ant-layout-sider.ant-layout-sider-dark {
    min-width: 0px !important;
  }

  .ant-layout .site-layout {
    margin-left: 0px !important;
  }
 
}