body {
  scroll-behavior: smooth;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

/* antd customized */
.ant-layout-sider {
  background-color: #F5F5F5 !important;
}

aside.ant-layout-sider.ant-layout-sider-dark {
  min-width: 216px !important;
}

.ant-layout-header {
  /* margin-left: 14px !important; */
}

.ant-menu-item-selected {
  background: #404040 !important;
  color: white !important;
}

.ant-menu-inline .ant-menu-item {
  border-radius: initial !important;
  margin-inline: 0px !important;
}

.ant-menu-inline .ant-menu-item {
  background-color: #f1f1f1;
}

.ant-checkbox .ant-checkbox-inner {
  width: 18px !important;
  height: 18px !important;
  border: 1px solid #404040 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #404040 !important;
}

.ant-input[disabled] {
  color: #4c4a4a !important;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
  width: 150px;
  height: 150px;
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error {
  border: 1px solid lightgray;
}

/* .ant-modal .ant-modal-content {
  padding: 0 !important;
} */